import React, { lazy } from "react";
import useLoader from "../../config/hooks/useLoader";
import { usePageLoader } from "../../config/context/pageloader";

export default function Loader() {
  const { loading } = usePageLoader();
  return (
    loading && (
      <div id="preloader">
        <div class="preloader">
          <span></span>
          <span></span>
        </div>
      </div>
    )
  );
}
