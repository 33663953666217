import React from "react";
import Header from "../components/blocks/Header";
import Hero from "../components/blocks/Hero";
import About from "../components/blocks/About";
import Footer from "../components/blocks/Footer";
import Sponsors from "../components/blocks/Sponsors";
import Speakers from "../components/blocks/Speakers";
import Scope from "../components/blocks/Scope";
import Schedule from "../components/blocks/Schedule";


import './Home.css'
import Contact from "../components/blocks/Contact";
import Opportunity from "../components/blocks/Opportunity";
import Countdown from "../components/blocks/Countdown";
import Submission from "../components/blocks/Submission";

export default function Home() {
  return (
    <>
      <Header />
      <Hero/>
      <Countdown/>
      <About/>
      <Scope/>
      <Opportunity/>
      <Speakers/>
      <Schedule/>
      <Submission/>
      <Contact/>
      <Footer/>
    </>
  );
}
