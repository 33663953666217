import React from "react";

export default function Schedule() {
  return (
    <div class="meeta-event-schedule meeta-event-schedule-2 section-padding">
      <div class="container">
        <div class="meeta-section-title-2 text-center">
          <h2 class="main-title">Important Dates</h2>
        </div>
        <div class="row gy-5 justify-content-between">
          <div class="col-lg-12">
            <div class="meeta-event-schedule-tabs">
              <div class="meeta-event-schedule-tab-content">
                <div id="tab1" class="meeta-event-schedule-tab-pane active">
                  <ul class="meeta-event-accordion">
                    <li class="meeta-event-accordion-item">
                      <div class="meeta-event-accordion-toggle">
                        <div class="event-title">
                          <span class="time">24 July 2023</span>
                          <h3 class="title">Paper Submission</h3>
                        </div>
                      </div>
                    </li>

                    <li class="meeta-event-accordion-item">
                      <div class="meeta-event-accordion-toggle">
                        <div class="event-title">
                          <span class="time">20 Agustus 2023</span>
                          <h3 class="title">Announcement </h3>
                        </div>
                      </div>
                    </li>

                    <li class="meeta-event-accordion-item">
                      <div class="meeta-event-accordion-toggle">
                        <div class="event-title">
                          <span class="time">1 September 2023</span>
                          <h3 class="title">Payment Deadline</h3>
                        </div>
                      </div>
                      <div class="meeta-event-accordion-body">
                        <p>
                          We’re inviting the top creatives in the tech industry
                          from all over the world to come learn, grow, scrape
                          their knees, try new things, to be vulnerable, and to
                          have epic adventures
                        </p>
                      </div>
                    </li>

                    <li class="meeta-event-accordion-item">
                      <div class="meeta-event-accordion-toggle">
                        <div class="event-title">
                          <span class="time">29 - 30 September 2023</span>
                          <h3 class="title">Conference</h3>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div id="tab2" class="meeta-event-schedule-tab-pane">
                  <ul class="meeta-event-accordion">
                    <li class="meeta-event-accordion-item">
                      <div class="meeta-event-accordion-toggle">
                        <div class="event-title">
                          <span class="time">29 - 30 September 2023</span>
                          <h3 class="title">Conference</h3>
                        </div>
                      </div>
                      <div class="meeta-event-accordion-body open">
                        <p>
                          We’re inviting the top creatives in the tech industry
                          from all over the world to come learn, grow, scrape
                          their knees, try new things, to be vulnerable, and to
                          have epic adventures
                        </p>
                      </div>
                    </li>

                    <li class="meeta-event-accordion-item">
                      <div class="meeta-event-accordion-toggle">
                        <div class="event-title">
                          <span class="time">11 AM - 01 AM</span>
                          <h3 class="title">Introduction about speakers </h3>
                        </div>
                      </div>
                      <div class="meeta-event-accordion-body">
                        <p>
                          We’re inviting the top creatives in the tech industry
                          from all over the world to come learn, grow, scrape
                          their knees, try new things, to be vulnerable, and to
                          have epic adventures
                        </p>
                      </div>
                    </li>

                    <li class="meeta-event-accordion-item">
                      <div class="meeta-event-accordion-toggle">
                        <div class="event-title">
                          <span class="time">01 AM - 02 AM</span>
                          <h3 class="title">Lunch Break </h3>
                        </div>
                      </div>
                      <div class="meeta-event-accordion-body">
                        <p>
                          We’re inviting the top creatives in the tech industry
                          from all over the world to come learn, grow, scrape
                          their knees, try new things, to be vulnerable, and to
                          have epic adventures
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div id="tab3" class="meeta-event-schedule-tab-pane">
                  <ul class="meeta-event-accordion">
                    <li class="meeta-event-accordion-item">
                      <div class="meeta-event-accordion-toggle">
                        <div class="event-title">
                          <span class="time">11 AM - 01 AM</span>
                          <h3 class="title">Introduction about speakers </h3>
                        </div>
                      </div>
                      <div class="meeta-event-accordion-body">
                        <p>
                          We’re inviting the top creatives in the tech industry
                          from all over the world to come learn, grow, scrape
                          their knees, try new things, to be vulnerable, and to
                          have epic adventures
                        </p>
                      </div>
                    </li>

                    <li class="meeta-event-accordion-item">
                      <div class="meeta-event-accordion-toggle">
                        <div class="event-title">
                          <span class="time">09 AM - 11 AM</span>
                          <h3 class="title">
                            Registration for opening workshop{" "}
                          </h3>
                        </div>
                      </div>
                      <div class="meeta-event-accordion-body open">
                        <p>
                          We’re inviting the top creatives in the tech industry
                          from all over the world to come learn, grow, scrape
                          their knees, try new things, to be vulnerable, and to
                          have epic adventures
                        </p>
                      </div>
                    </li>

                    <li class="meeta-event-accordion-item">
                      <div class="meeta-event-accordion-toggle">
                        <div class="event-title">
                          <span class="time">01 AM - 02 AM</span>
                          <h3 class="title">Lunch Break </h3>
                        </div>
                      </div>
                      <div class="meeta-event-accordion-body">
                        <p>
                          We’re inviting the top creatives in the tech industry
                          from all over the world to come learn, grow, scrape
                          their knees, try new things, to be vulnerable, and to
                          have epic adventures
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div id="tab4" class="meeta-event-schedule-tab-pane">
                  <ul class="meeta-event-accordion">
                    <li class="meeta-event-accordion-item">
                      <div class="meeta-event-accordion-toggle">
                        <div class="event-title">
                          <span class="time">09 AM - 11 AM</span>
                          <h3 class="title">
                            Registration for opening workshop{" "}
                          </h3>
                        </div>
                      </div>
                      <div class="meeta-event-accordion-body open">
                        <p>
                          We’re inviting the top creatives in the tech industry
                          from all over the world to come learn, grow, scrape
                          their knees, try new things, to be vulnerable, and to
                          have epic adventures
                        </p>
                      </div>
                    </li>

                    <li class="meeta-event-accordion-item">
                      <div class="meeta-event-accordion-toggle">
                        <div class="event-title">
                          <span class="time">11 AM - 01 AM</span>
                          <h3 class="title">Discussion about technolgy </h3>
                        </div>
                      </div>
                      <div class="meeta-event-accordion-body">
                        <p>
                          We’re inviting the top creatives in the tech industry
                          from all over the world to come learn, grow, scrape
                          their knees, try new things, to be vulnerable, and to
                          have epic adventures
                        </p>
                      </div>
                    </li>

                    <li class="meeta-event-accordion-item">
                      <div class="meeta-event-accordion-toggle">
                        <div class="event-title">
                          <span class="time">01 AM - 02 AM</span>
                          <h3 class="title">Lunch Break </h3>
                        </div>
                      </div>
                      <div class="meeta-event-accordion-body">
                        <p>
                          We’re inviting the top creatives in the tech industry
                          from all over the world to come learn, grow, scrape
                          their knees, try new things, to be vulnerable, and to
                          have epic adventures
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
