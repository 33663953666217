import React from "react";
import { WebLogo } from "../../assets";

export default function Footer() {
  return (
    <div id="payment" class="meeta-footer-section meeta-footer-2">
      <div class="footer-widget">
        <div class="container">
          <div class="footer-wrap">
            <div class="row">
              <div class="col-lg-3">
                <div class="footer-logo">
                  <a href="index.html">
                    <img
                      src={WebLogo}
                      style={{ maxWidth: 200 + "px" }}
                      alt="Logo"
                    />
                  </a>
                </div>
              </div>

              <div class="col-lg-9">
                <div class="footer-bottom-wrap" style={{ marginTop: 0 }}>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="footer-info">
                        <h3 class="title">Conference Details</h3>
                        <span class="date">29 - 30 September 2023 </span>
                        <p>
                          Universitas Negeri Malang Jl. Semarang 5 Malang, 65145
                          Telp. (0341) 551312 Malang, Indonesia
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="footer-info">
                        <h3 class="title">Registration Fee</h3>
                        <ul>
                          <li>
                            <p class="date">
                              Paper Submission : <br />{" "}
                              <span class="text-white">24 July 2023</span>
                            </p>
                          </li>
                          <li>
                            <p class="date">
                              Announcement : <br />{" "}
                              <span class="text-white">20 August 2023</span>
                            </p>
                          </li>
                          <li>
                            <p class="date">
                              Payment Deadline : <br />{" "}
                              <span class="text-white">1 September 2023</span>
                            </p>
                          </li>
                          <li>
                            <p class="date">
                              Conference : <br />{" "}
                              <span class="text-white">
                                29 - 30 September 2023
                              </span>
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="footer-info">
                        <h3 class="title">Payment & Contact</h3>
                        <br />
                        <ul>
                          <li>
                            <p>
                              PT Bank Syariah Indonesia Tbk (Kode Bank 451)
                              7722242275 a.n. KONSORSIUM PTC NUSANTARA{" "}
                            </p>
                            <a class="btn-2" href="https://s.id/1Q5N9">
                              Registration Link
                            </a>
                          </li>
                          <li>
                            <p class="date">Contact Person : </p>
                            <p>
                              Wouri Astuti +62 813-1405-5457, Aji Titin Rosita
                              +62 853-1522-2270, Anna +62 821-2708-0517
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="footer-copyright text-center">
            <p>2021 Copyright Expotin Designed by Love. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
}
