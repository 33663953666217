import React from "react";
import { WebLogo } from "../../assets";

export default function Header() {
  return (
    <div class="meeta-header-section meeta-header-2">
      <div class="header-middle header-sticky">
        <div class="container">
          <div class="header-wrap">
            <div class="header-logo">
              <a href="/">
                <img
                  src={WebLogo}
                  style={{ maxWidth: 150 + "px" }}
                  alt="Logo"
                />
              </a>
            </div>
            <div class="header-navigation d-none d-lg-block">
              <ul class="main-menu">
                <li class="active-menu">
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="#about">About</a>
                </li>
                <li>
                  <a href="#speakers">Speaker</a>
                </li>
                <li>
                  <a href="#location">Location</a>
                </li>
                <li>
                  <a href="#payment">Payment</a>
                </li>
                <li>
                  <a href="https://stkippgrisitubondo.com/">Submission</a>
                </li>
              </ul>
            </div>
            <div class="header-meta">
              <div class="header-btn d-none d-md-block">
                <a href="https://s.id/1Q5N9" class="btn btn-primary">
                  Register Now
                </a>
              </div>
              <div class="header-toggle d-lg-none">
                <button
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasExample"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
