import React, { useEffect, useState } from "react";

export default function Countdown() {
  const [time, setTime] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [targetTime, setTargetTime] = useState(
    new Date("2023-09-30T23:59:59").getTime()
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      const remaining = targetTime - currentTime;

      if (remaining <= 0) {
        clearInterval(intervalId);
        setTimeRemaining(0);
      } else {
        setTimeRemaining(remaining);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [targetTime]);

  const formatTime = (milliseconds = timeRemaining) => {
    const seconds = Math.floor(milliseconds / 1000) % 60;
    const minutes = Math.floor(milliseconds / (1000 * 60)) % 60;
    const hours = Math.floor(milliseconds / (1000 * 60 * 60)) % 24;
    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));

    return {seconds, minutes, hours, days};
  };

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      setTime(new Date().toLocaleString());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [time]);

  return (
    <div class="meeta-register-countdown register-countdown-4">
      <div class="container position-relative">
        <img
          class="count-shape-1"
          src="assets/images/shape/count-shape-1.png"
          alt=""
        />
        <img
          class="count-shape-2"
          src="assets/images/shape/count-shape-2.png"
          alt=""
        />
        <div class="meeta-register-countdown-wrapper">
          <div class="row gy-5 align-items-center">
            <div class="col-lg-12">
              <div
                class="meeta-countdown countdown item-0"
                data-countdown="2024/10/24"
                data-format="short"
              >
                <div class="single-countdown">
                  <span class="count countdown__time daysLeft">{formatTime().days}</span>
                  <span class="value countdown__time daysText">Days</span>
                </div>
                <div class="single-countdown">
                  <span class="count countdown__time hoursLeft">{formatTime().hours}</span>
                  <span class="value countdown__time hoursText">Hours</span>
                </div>
                <div class="single-countdown">
                  <span class="count countdown__time minsLeft">{formatTime().minutes}</span>
                  <span class="value countdown__time minsText">Mints</span>
                </div>
                <div class="single-countdown">
                  <span class="count countdown__time secsLeft">{formatTime().seconds}</span>
                  <span class="value countdown__time secsText">Secs</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
