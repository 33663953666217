import React from "react";
import {
  FlyerIMG,
  KptcnIMG,
  ScopusLGIMG,
  SintaLGIMG,
  UMIMG,
  UmIMG,
} from "../../assets";

export default function Submission() {
  return (
    <div class="meeta-about-section-4 section-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="container mt-5">
              <div class="meeta-section-title-2 section-title-3 text-center">
                <h2 class="main-title">Registration & Submission</h2>
              </div>
              <div>
                <h2 class="title">Submission of Abstract and Full Paper</h2>
                <p>
                 <span class="text-danger fw-bold">[IMPORTANT]</span> To submit an
                  abstract, authors must create an account first. Then they can
                  submit their abstracts by logging in to their account. The
                  manuscript (full paper) can be submitted only after authors
                  submit their abstract. The whole submission process is done
                  fully online <span class="fw-bold text-danger">(NOT by email)</span> to guarantee smooth
                  administration. Letter of Acceptance (LoA) and Letter of
                  Invitation (LoI) can be downloaded directly from your account
                  once your abstract is accepted to be presented at the
                  conference.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="container mt-5">
            <h2 class="title">Payment of Registration Fee</h2>
              <p>
                <span class="fw-bold text-danger">[IMPORTANT]</span> After making the
                payment, author MUST upload the payment proof through their
                account <span class="fw-bold text-danger">(NOT Email)</span>. Author can upload by logging in to the
                online system. Payment receipt can be downloaded directly from
                your account once your payment is confirmed by the committee.
              </p>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="container mt-5">
              <div class="row  justify-content-center">
                <div class="col-sm-12">
                  <div class="form-btn">
                    <a
                      href="https://s.id/1Q5N9"
                      class="btn-2 text-center"
                      type="submit"
                    >
                      Download Template zip
                    </a>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-btn">
                    <a
                      href="https://s.id/1Q5N9"
                      class="btn-2 text-center"
                      type="submit"
                    >
                      Download Template word
                    </a>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-btn">
                    <a
                      href="https://s.id/1Q5N9"
                      class="btn-2 text-center"
                      type="submit"
                    >
                      Registration
                    </a>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-btn">
                    <a
                      href="https://stkippgrisitubondo.com/"
                      class="btn-2 text-center"
                      type="submit"
                    >
                      Submission
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
