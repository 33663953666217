import React from "react";

export default function Scope() {
  return (
    <div class="meeta-pricing section-padding">
      <div class="container">
        <div class="meeta-section-title-2 text-center">
          <h2 class="main-title text-white">Sub Theme</h2>
        </div>

        <div class="row gy-5 justify-content-center meeta-pricing-row">
          <div class="col-lg-4 col-md-8">
            <div class="single-pricing">
              <div class="pricing-body">
                <ul>
                  <li>Social</li>
                  <li>Science Technology</li>
                  <li>Economics</li>
                </ul>
                <a class="btn btn-3" href="https://s.id/1Q5N9">
                  Register
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-8">
            <div class="single-pricing active">
              <div class="pricing-body">
                <ul>
                  <li>Education</li>
                  <li>Law</li>
                  <li>Health</li>
                  <li>Humaniora</li>
                </ul>
                <a class="btn btn-3" href="https://s.id/1Q5N9">
                  Register
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-8">
            <div class="single-pricing">
              <div class="pricing-body">
                <ul>
                  <li>Management</li>
                  <li>Accounting</li>
                </ul>
                <a class="btn btn-3" href="https://s.id/1Q5N9">
                  Register
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
