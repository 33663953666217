import React from "react";
import {
  FlyerIMG,
  KptcnIMG,
  ScopusLGIMG,
  SintaLGIMG,
  UMIMG,
  UmIMG,
} from "../../assets";

export default function Opportunity() {
  return (
    <div class="meeta-about-section-4 section-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="meeta-about-content-wrap">
              <div class="meeta-section-title-2 section-title-4">
                <h2 class="main-title">Oppurtunity</h2>
              </div>
              <div class="meeta-about-content-4">
                <ul>
                  <li>
                    <p>
                      Free Publication Proceeding Publication Scopus dan SINTA
                      2-6 (Selected Paper).
                    </p>
                  </li>
                  <li>
                    <p>Free Certificate.</p>
                  </li>
                  <li>
                    <p>Free materi.</p>
                  </li>
                  <li>
                    <p>Snack Box (On Site)</p>
                  </li>
                  <li>
                    <p>MoU dan MoA</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="meeta-about-content-wrap">
              <div class="meeta-section-title-2 section-title-4">
                <h2 class="main-title">Registration Fee</h2>
              </div>
              <div class="meeta-about-content-4">
                <ul>
                  <li>
                    <p>Presenter On Site : IDR 1.500.000</p>
                  </li>
                  <li>
                    <p> Presenter Online : IDR 1.000.000</p>
                  </li>
                  <li>
                    <p> Participant On Site : IDR 500.000 </p>
                  </li>
                  <li>
                    <p>Participant Online : IDR 150.000</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="meeta-about-content-wrap">
              <div class="meeta-section-title-2 section-title-4">
                <h2 class="main-title">Important Dates</h2>
              </div>
              <div class="meeta-about-content-4">
                <ul>
                  <li>
                    <p>Paper Submission : 24 July 2023</p>
                  </li>
                  <li>
                    <p>Announcement : 20 August 2023</p>
                  </li>
                  <li>
                    <p>Payment Deadline : 1 September 2023</p>
                  </li>
                  <li>
                    <p>Conference : 29 - 30 September 2023</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="container mt-5">
              <div class="meeta-section-title-2 section-title-3 text-center">
                <h2 class="main-title">Publication Indexing</h2>
              </div>
              <div class="meeta-sponsor-wrap">
                <div class="row justify-content-center align-items-center">
                  <div class="col-lg-3 col-sm-6">
                    <div class="meeta-sponsor-logo">
                      <a href="#">
                        <img src={ScopusLGIMG} alt="" />
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6">
                    <div class="meeta-sponsor-logo">
                      <a href="#">
                        <img src={SintaLGIMG} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
