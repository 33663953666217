import React from "react";
import {
  AhmadYsfIMG,
  MahfudIMG,
  Speaker3IMG,
  Speaker4IMG,
  Speaker5IMG,
  Speaker6IMG,
} from "../../assets";

export default function Speakers() {
  return (
    <div id="speakers" class="meeta-speakers section-padding">
      <div class="container">
        <div class="meeta-section-title-2 text-center">
          <h2 class="main-title">Keynote Speakers</h2>
        </div>

        <div class="row gy-5 meeta-speakers-row">
          <div class="col-lg-4 col-sm-6">
            <div class="single-speaker">
              <div class="speaker-image">
                <a href="speaker-single.html">
                  <img src={MahfudIMG} aly="" />
                </a>
              </div>
              <div class="speaker-content">
                <div class="speaker-content-box">
                  <h4 class="speaker-name">
                    <a href="#">Prof, Dr. H. Mahfud MD., SH., Mhum </a>
                  </h4>
                  <p class="speaker-designation">Indonesia</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="single-speaker">
              <div class="speaker-image">
                <a href="speaker-single.html">
                  <img src={AhmadYsfIMG} aly="" />
                </a>
              </div>
              <div class="speaker-content">
                <div class="speaker-content-box">
                  <h4 class="speaker-name">
                    <a href="#">Dr. Ahmad Yusuf Sobri , S.Sos, M.Pd</a>
                  </h4>
                  <p class="speaker-designation">
                    Dean Faculty of Education Universitas Negeri Malang
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="single-speaker">
              <div class="speaker-image">
                <a href="speaker-single.html">
                  <img src={Speaker3IMG} aly="" />
                </a>
              </div>
              <div class="speaker-content">
                <div class="speaker-content-box">
                  <h4 class="speaker-name">
                    <a href="#">Prof. Ts. Dr. Hajah Roziah Mohd Janor</a>
                  </h4>
                  <p class="speaker-designation">
                    NC- Univensiti Teknologi Mara
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="single-speaker">
              <div class="speaker-image">
                <a href="speaker-single.html">
                  <img src={Speaker4IMG} aly="" />
                </a>
              </div>
              <div class="speaker-content">
                <div class="speaker-content-box">
                  <h4 class="speaker-name">
                    <a href="#">Assoc. Prof, Dr, Kalsuhisa Shirai</a>
                  </h4>
                  <p class="speaker-designation">
                    Aichi Toho Univesity, Jepang
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="single-speaker">
              <div class="speaker-image">
                <a href="speaker-single.html">
                  <img src={Speaker5IMG} aly="" />
                </a>
              </div>
              <div class="speaker-content">
                <div class="speaker-content-box">
                  <h4 class="speaker-name">
                    <a href="#">Assoc. Prof. Dr. Ghayarnitsharr</a>
                  </h4>
                  <p class="speaker-designation">
                    Direction of foreign Affair - Pathum Thani Univensity of
                    Thailand.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="single-speaker">
              <div class="speaker-image">
                <a href="speaker-single.html">
                  <img src={Speaker6IMG} aly="" />
                </a>
              </div>
              <div class="speaker-content">
                <div class="speaker-content-box">
                  <h4 class="speaker-name">
                    <a href="#">Prof, Dato’ Dr. Mohd Ekhwan Hj. Toriman</a>
                  </h4>
                  <p class="speaker-designation">
                    NC-Universiti Kebangsaan Malaysia
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
