import React from "react";

export default function Contact() {
  return (
    <>
      <div id="location" class="section contact-info-section">
        <div class="container">
          <div class="contact-info-wrap">
            <div class="row justify-content-center">
              {/* <div class="col-lg-4 col-sm-6">
                <div class="single-contact-info text-center">
                  <div class="info-icon color-2">
                    <i class="flaticon-phone-call"></i>
                  </div>
                  <div class="info-content">
                    <h5 class="title">Contact Person</h5>
                    <div>
                      <p>Wuri Atuti</p>
                      <p>+62 813-1405-5457</p>
                      <p>Aji Titin Rosita</p>
                      <p>+62 853-1522-2270</p>
                      <p>Anna</p>
                      <p>+62 821-2708-0517</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6">
                <div class="single-contact-info text-center">
                  <div class="info-icon color-1">
                    <i class="flaticon-email"></i>
                  </div>
                  <div class="info-content">
                    <h5 class="title">Payment</h5>
                    <p>Bank Syariah Indonesia Tbk</p>
                    <p>Kode Bank (451) 7722242275</p>
                  </div>
                </div>
              </div> */}
              <div class="col-lg-8 col-xl-10 col-sm-6 col-12">
                <div class="single-contact-info text-center">
                  <div class="info-icon color-3">
                    <i class="flaticon-pin"></i>
                  </div>
                  <div class="info-content">
                    <div class="row">
                      <div class="col-lg-6">
                        <h5 class="title">Universitas Negeri Malang</h5>
                        <a href="https://s.id/1Q5N9">
                          {" "}
                          <p>
                            Jl. Semarang 5 Malang, 65145 Telp. (0341) 551312
                            Malang, Indonesia
                          </p>
                        </a>
                      </div>
                      <div class="col-lg-6">
                        <p class="title">Contact Person</p>
                        <p>
                          Wouri Astuti +62 813-1405-5457, Aji Titin Rosita +62
                          853-1522-2270, Anna +62 821-2708-0517
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section contact-map-section">
        <div class="contact-map-wrap">
          <iframe
            id="gmap_canvas"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63221.56583244234!2d112.54090334863281!3d-7.9629559999999975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e788281bdd08839%3A0xc915f268bffa831f!2sUniversitas%20Negeri%20Malang!5e0!3m2!1sid!2sid!4v1695534783745!5m2!1sid!2sid"
          ></iframe>
        </div>
      </div>
    </>
  );
}
