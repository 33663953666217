import React from "react";
import { FlyerIMG, KptcnIMG, UMIMG, UmIMG } from "../../assets";

export default function About() {
  return (
    <div id="about" class="meeta-about-section-4 section-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="meeta-about-counter-wrap">
              <div class="meeta-flyer">
                <img src={FlyerIMG} alt=""/>
                <a href={FlyerIMG} target="_blank" class="btn btn-secondary">Download Flyer</a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div
              class="meeta-about-content-wrap"
              style={{ marginTop: 1 + "rem" }}
            >
              <div class="meeta-section-title-2 section-title-4">
                <h2 class="main-title">
                  Konsorium Pendidikan Tinggi & Cendikiawan Nusantara and
                  Universitas Negeri Malang Proudly Presents
                </h2>
              </div>
              <div class="meeta-about-content-4">
                <p>
                  Welcome to the official website of the International
                  Conference on the Challenges and Opportunities in the
                  Development of Science, Technology, and Human Civilization in
                  the 6.0 Era (ICCTHC 2023), organized by the Consortium of
                  Higher Education & Nusantara Scholars in collaboration with
                  the State University of Malang. The conference will be held
                  virtually on September 29-30, 2023.
                  <br />
                  <br />
                  The overarching theme of the conference is "Challenges and
                  Opportunities in the Development of Science, Technology, and
                  Human Civilization in the 6.0 Era." This conference will delve
                  into the following key sub-themes:
                  <br /><br />
                  Education
                  <br />
                  Law
                  <br />
                  Health
                  <br />
                  Social Humanities
                  <br />
                  Science and Technology
                  <br />
                  Economics
                  <br />
                  Management
                  <br />
                  Accounting
                  <br /><br />
                  This conference provides a platform for delegates to exchange
                  fresh ideas in person, bringing together leading academic
                  scientists, researchers, and research scholars to exchange and
                  share their experiences and research findings in various
                  aspects of science, technology, and human civilization. We
                  anticipate that the outcomes of this conference will
                  significantly contribute to the knowledge base in these
                  ever-evolving scientific fields.
                </p>

                <div class="about-list">
                  <ul>
                    <li class="about-list-item">
                      <div class="about-icon">
                        <img src="assets/images/ab-icon-5.png" alt="" />
                      </div>
                      <div class="about-text">
                        <h3 class="title">Lokasi</h3>
                        <p>Universitas Malang, Indonesia</p>
                      </div>
                    </li>
                    <li class="about-list-item">
                      <div class="about-icon">
                        <img src="assets/images/ab-icon-6.png" alt="" />
                      </div>
                      <div class="about-text">
                        <h3 class="title">Waktu</h3>
                        <p>Jum'at dan Sabtu September 29 - 30, 2023 </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
