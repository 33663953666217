import React from "react";
import { Outlet } from "react-router-dom";
import Loader from "../../components/utils/Loader";

export default function Default() {
  return (
    <div class="main-wrapper">
      <Loader/>
      <Outlet />
    </div>
  );
}
