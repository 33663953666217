import React from "react";
import { UmIMG } from "../../assets";

export default function Hero() {
  return (
    <div
      class="meeta-hero-section-4 d-flex align-items-center"
      style={{ backgroundImage: `url(${UmIMG})`, backgroundSize: "cover" }}
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-7">
            <div class="hero-content">
              <div
                class="hero-date aos-init aos-animate"
                data-aos-delay="700"
                data-aos="fade-up"
              >
                <h3 class="date">29 - 30</h3>
                <span class="year">
                  September <br /> 2023
                </span>
              </div>
              <span
                class="place aos-init aos-animate"
                data-aos-delay="800"
                data-aos="fade-up"
              >
                Universitas Negeri Malang, Indonesia
              </span>
              <h2 class="sub-title aos-init aos-animate">Proudly present:</h2>
              <h2
                class="title aos-init aos-animate"
                data-aos-delay="900"
                data-aos="fade-up"
              >
                Challenges and Opportunities for the Development of Science,
                Technology and Human Civilization in the 6.0 Era
              </h2>
            </div>
          </div>
          <div class="col-lg-5">
            <div
              class="hero-form aos-init aos-animate"
              data-aos-delay="900"
              data-aos="fade-up"
            >
              <div class="hero-form-wrap">
                <div class="heading-wrap text-center">
                  <h3 class="title">Submission & Registration</h3>
                </div>
                <form action="#">
                  <div class="row">
                  <div class="col-sm-12">
                      <div class="form-btn">
                        <a href="https://s.id/1Q5N9" class="btn-2 text-center" type="submit">
                          Download Template zip
                        </a>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-btn">
                        <a href="https://s.id/1Q5N9" class="btn-2 text-center" type="submit">
                          Download Template word
                        </a>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-btn">
                        <a href="https://s.id/1Q5N9" class="btn-2 text-center" type="submit">
                          Registration
                        </a>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-btn">
                        <a href="https://stkippgrisitubondo.com/" class="btn-2 text-center" type="submit">
                          Submission
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shape" style={{ position: "absolute", left: 0, bottom: 0 }}>
        <img src="assets/images/shape/hero-4-shape.png" alt="" />
      </div>
    </div>
  );
}
