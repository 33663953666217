import { Children, createContext, useContext, useState } from "react";
import React from "react";

const MyContext = createContext();

export function PageLoader({ children }) {
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setLoading(!loading);
  };

  const setPageLoad = (load = false) => {
    setLoading(load);
  };

  return (
    <MyContext.Provider value={{ loading, toggle,setPageLoad }}>
      {children}
    </MyContext.Provider>
  );
}

export function usePageLoader() {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error("usePageLoader harus digunakan di PageLoader");
  }
  return context;
}
